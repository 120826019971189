import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip, Icon } from '@popmenu/common-ui';
import { compose } from '@shakacode/recompose';
import { Help, Eye } from '@popmenu/web-icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useMutation } from '~/lazy_apollo/client';
import { FormattedMessage } from 'react-intl';

import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../../utils/arrays';
import { formatPhone } from '../../../utils/strings';
import { withIntl } from '../../../utils/withIntl';
import { withRestaurant } from '../../../utils/withRestaurant';
import { useSnackbar } from '../../../utils/withSnackbar';
import { withStyles } from '../../../utils/withStyles';
import { currentUserShape, withCurrentUser } from '../../../shared/CurrentSessionProvider';
import { notBlankValidator, passwordValidator, phoneValidator } from '../../../utils/forms';
import updateUserMutation from '../../../libs/gql/mutations/users/updateUserMutation.gql';
import updateFollowerMutation from '../../../libs/gql/mutations/users/updateFollowerMutation.gql';
import { useSignOutProfile, useProfileUser } from '../hooks';
import { BirthdayControls } from '../internal/BirthdayControls';

import BasicForm, {
  TextFieldGroup,
  SubmitGroup,
} from '../../../admin/shared/forms/BasicForm';
import MarketingOptInRequest from '../../../shared/sessions/MarketingOptInRequest';
import SelectGroup from '../../../admin/shared/forms/SelectGroup';
import { useFeatureFlags } from '../../../utils/featureFlagsContext';
import BasicFormConfirmModal from '../../../admin/shared/forms/BasicForm/BasicFormConfirmModal';

const styles = () => ({
  container: {
    height: 'calc(100vh - 170px)',
    overflowY: 'scroll',
  },
  optInText: {
    fontSize: '1em !important',
  },
});

export const getPhoneHelperText = (isDoNotCallListEnabledParam) => {
  let phoneHelperText = (
    <FormattedMessage
      id="models.user.phone_helper_text"
      defaultMessage="000-000-0000 or (000) 000-0000"
    />
  );
  if (isDoNotCallListEnabledParam) {
    phoneHelperText = (
      <FormattedMessage
        id="user.phone_dnc_helper_text"
        defaultMessage="Please note that if your phone number is listed on the national Do Not Call registry, you will not
receive any marketing communications via text message"
      />
    );
  }
  return phoneHelperText;
};

const MyInfo = (props) => {
  const { isFeatureActive } = useFeatureFlags();
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');
  const isDoNotCallListEnabled = isFeatureActive('do_not_call_list');
  const { showSnackbar } = useSnackbar();
  const { signOutProfile } = useSignOutProfile();

  const [validate, setValidate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const profileUserQuery = useProfileUser();
  const [updateUser] = useMutation(updateUserMutation);
  const formEndRef = useRef(null);
  const { phone } = props.currentUser;

  const isMobile = isWidthDown('sm', props.width);

  useEffect(() => {
    if (openedMenu) {
      formEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [openedMenu]);

  const follower = props.currentUser.followers.find(f => f.restaurantId === props.restaurant.id);

  const userMutation = (variables, signOut) => {
    const nameArray = variables.name && variables.name.split(' ');
    updateUser({
      variables: {
        userId: props.currentUser.id,
        userInput: {
          ...variables,
          birthDay: variables.birthDay ? parseInt(variables.birthDay, 10) : null,
          birthMonth: variables.birthMonth ? parseInt(variables.birthMonth, 10) : null,
          favoriteLocationId: variables.favoriteLocationId,
          firstName: nameArray ? (nameArray.length >= 2 ? nameArray.slice(0, nameArray.length - 1).join(' ') : variables.name) : undefined,
          lastName: variables.name ? (nameArray.length >= 2 ? nameArray[nameArray.length - 1] : '') : undefined,
          name: undefined,
          phone: variables.phone,
          restaurantId: props.restaurant.id,
        },
      },
    }).then((response) => {
      if (response.data.updateUser) {
        showSnackbar('Profile updated!');
        createEvent({
          eventableType: 'GuestProfile',
          eventLabel: 'profile | my_info | update_button | update',
          eventType: 'profile_my_info_update_click',
        });
        if (signOut) {
          signOutProfile();
        }
      }
    });
  };

  const enabledLocations = profileUserQuery.query.data?.restaurant?.locations?.filter(({ isLocationEnabled }) => isLocationEnabled);
  const singleLocationId = enabledLocations?.length === 1 ? enabledLocations[0]?.id : undefined;

  return (
    <Box
      className={props.classes.container}
      display="flex"
      flexDirection="column"
    >
      <BasicForm
        defaultValues={{
          birthDay: props.currentUser.birthDay,
          birthMonth: props.currentUser.birthMonth,
          email: props.currentUser.email,
          favoriteLocationId: follower?.favoriteLocation?.id || singleLocationId,
          name: props.currentUser.lastName ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : props.currentUser.firstName,
          password: props.currentUser.isPendingPassword ? null : '************',
          phone: phone ? formatPhone(phone) : null,
        }}
        onSubmit={(variables) => {
          if (isLoyaltyEnabled && ((phone && formatPhone(phone)) !== (variables.phone && formatPhone(variables.phone)))) {
            setOpenModal(true);
          } else {
            userMutation(variables);
          }
        }}
      >
        {({ setValues, values }) => {
          if (!values.name || !values.email) {
            setValidate(false);
          } else {
            setValidate(true);
          }
          if (values.phone) {
            setValues({ ...values, phone: formatPhone(values.phone) });
          }
          return (
            <React.Fragment>
              <Box
                paddingTop={1}
                paddingBottom={isMobile ? 3 : undefined}
              >
                <TextFieldGroup
                  field="name"
                  title={props.t('models.user.name')}
                  validate={notBlankValidator}
                />
                <TextFieldGroup
                  field="email"
                  disabled={!!props.currentUser.email && !!values.email} // we allow a user to add an email if they don't have one
                  title={props.t('models.user.email')}
                  type="email"
                  validate={notBlankValidator}
                />
                <TextFieldGroup
                  field="password"
                  title={props.t('models.user.update_password')}
                  type={showPassword ? 'text' : 'password'}
                  validate={values.password !== '************' ? passwordValidator : undefined}
                  onFocus={(e) => {
                    if (e.target.value === '************') {
                      e.target.setSelectionRange(0, 12);
                    }
                  }}
                  InputProps={{
                    endAdornment:
                      (
                        <React.Fragment>
                          {values.password && values.password !== '************' && (
                            <Button
                              data-cy={'preview_password'}
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              size="xs"
                              variant="text"
                            >
                              <Box color={!showPassword && 'gray.dark'} display="flex">
                                <Icon icon={Eye} />
                              </Box>
                            </Button>
                          )}
                          <Tooltip title={props.t('models.user.password_tooltip')} enterTouchDelay={25}>
                            <Box>
                              <Icon icon={Help} />
                            </Box>
                          </Tooltip>
                        </React.Fragment>
                      ),
                  }}
                  helperText={props.t('models.user.password_helper_text')}
                />
                <TextFieldGroup
                  field="phone"
                  title={props.t('models.user.phone')}
                  type="tel"
                  InputProps={{
                    endAdornment:
                      (
                        <Tooltip title={props.t('models.user.phone_tooltip')} enterTouchDelay={25}>
                          <Box paddingLeft={1.7}>
                            <Icon icon={Help} />
                          </Box>
                        </Tooltip>
                      ),
                  }}
                  validate={phoneValidator}
                  helperText={getPhoneHelperText(isDoNotCallListEnabled)}
                />
                <BirthdayControls />
                <Box>
                  <SelectGroup
                    field="favoriteLocationId"
                    options={sortByKey(enabledLocations?.map(option => ({
                      label: option.name,
                      value: option.id,
                    })), 'label')}
                    title={props.t('models.follower.favorite_location.name')}
                    onMenuOpen={() => setOpenedMenu(true)}
                    onMenuClose={() => setOpenedMenu(false)}
                    validate={notBlankValidator}
                  />
                </Box>
                <Box ref={formEndRef} />
              </Box>
              <Box>
                <SubmitGroup
                  disabled={!validate}
                  color="primary"
                  justify={!isMobile && 'left'}
                  title={props.t('models.user.update')}
                  block={!!isMobile}
                />
              </Box>
              <BasicFormConfirmModal
                closeModal={() => setOpenModal(false)}
                message={<FormattedMessage id="confirm_phone_change.body" defaultMessage="Updating your phone number will log you out and require you to validate your number again. Do you still wish to update?" />}
                onSubmit={() => userMutation(values, true)}
                title={<FormattedMessage id="confirm_phone_change.title" defaultMessage="Confirm Update" />}
                showModal={openModal}
              />
            </React.Fragment>
          );
        }}
      </BasicForm>

      {follower && (
        <BasicForm
          defaultValues={{
            subscribeEmail: !follower.isUnsubscribed,
            subscribeSms: !follower.isSmsUnsubscribed,
          }}
          mutate={{
            mutation: updateFollowerMutation,
            onCompleted: () => {
              showSnackbar('Subscriptions updated!');
            },
            toVariables: variables => ({
              followerId: follower.id,
              followerInput: {
                isSmsUnsubscribed: !variables.subscribeSms,
                isUnsubscribed: !variables.subscribeEmail,
              },
            }),
          }}
        >
          <React.Fragment>
            <Box
              paddingTop={1}
              paddingBottom={isMobile ? 3 : undefined}
            >
              <MarketingOptInRequest
                showBothOptIns
                textClassName={props.classes.optInText}
              />
            </Box>
            <Box>
              <SubmitGroup
                disabled={!validate}
                color="primary"
                justify={!isMobile && 'left'}
                title="Update Subscriptions"
                block={!!isMobile}
              />
            </Box>
          </React.Fragment>
        </BasicForm>
      )}
    </Box>
  );
};

MyInfo.propTypes = {
  currentUser: currentUserShape.isRequired,
  restaurant: PropTypes.object.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
  withRestaurant,
  withStyles(styles),
)(withWidth()(MyInfo));
