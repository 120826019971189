import { useDispatch } from '~/utils/redux';
import { useIsOrderingApp } from '../useIsOrderingApp';
import { closeProfileV2Modal } from '../../../../shared/ModalActions';

export const useCloseProfile = () => {
  const dispatch = useDispatch();
  const isOrderingApp = useIsOrderingApp();

  const closeProfile = () => {
    if (isOrderingApp) {
      // @ts-expect-error orderingApp typed dispatch
      dispatch({ type: 'ordering.dismissDialog' });
    } else {
      dispatch(closeProfileV2Modal());
    }
  };

  return closeProfile;
};
