import React from 'react';
import { Box, Button } from '@popmenu/common-ui';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import OrdersEmptyState from '../../../../assets/svg/popmenu_guest_profile_orders_empty.svg';
import OrderHistoryCard from './OrderHistoryCard';
import { profileOrderHistoryContainer } from '../../profile.styles';
import { useOrderCartUrl, useCloseProfile, useProfileUser, useIsOrderingApp } from '../../hooks';

const useStyles = makeStyles(profileOrderHistoryContainer);

const OrderHistoryPage = () => {
  const classes = useStyles();
  const orderCartUrl = useOrderCartUrl();
  const history = useHistory();
  const closeProfile = useCloseProfile();
  const isOrderingApp = useIsOrderingApp();
  const profileUserQuery = useProfileUser();
  const orderHistory = profileUserQuery.query.data?.guestProfileUser?.orderHistory || [];

  const onOrder = () => {
    if (!isOrderingApp) {
      history.push(orderCartUrl());
    }
    closeProfile();
  };

  if (orderHistory.length === 0) {
    return (
      <Box
        width="100%"
        paddingTop={7}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingLeft={3}>
          <OrdersEmptyState height={200} />
        </Box>
        <Box paddingY={4} color="gray.dark">
          <FormattedMessage id="profile.order_history_empty" defaultMessage="No Orders Yet" />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onOrder}
        >
          <FormattedMessage id="profile.order_history_order_now" defaultMessage="Order Now" />
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {orderHistory.map((orderCart, index) => <OrderHistoryCard orderCart={orderCart} index={index} />)}
    </Box>
  );
};

export default OrderHistoryPage;
