import React from 'react';
import { Box } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import OfferCard from './OfferCard';
import OffersEmptyState from '../../../assets/svg/popmenu_guest_profile_offers_empty.svg';
import { useProfileUser } from '../hooks';

const Offers = () => {
  const profileUserQuery = useProfileUser();
  const orderingOfferCodes = profileUserQuery.query.data?.guestProfileUser?.orderingOfferCodes;

  return (
    <Box flexGrow={1} overflow="auto" data-cy="profile_offers_list">
      {orderingOfferCodes?.length > 0 ? orderingOfferCodes?.map(offer => (
        <Box marginBottom={1} key={offer.id}>
          <OfferCard
            locationId={offer.orderingOffer.locationId}
            locationName={offer.orderingOffer.locationName}
            locationSlug={offer.orderingOffer.locationSlug}
            orderingOfferCode={offer}
            offerName={offer.orderingOffer.name}
            offerCode={offer.code}
            expirationDate={offer.orderingOfferExpirationDate}
            photo={offer.orderingOffer.imageUploadedPhoto}
          />
        </Box>
      )) : (
        <Box
          width="100%"
          paddingTop={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <OffersEmptyState height={200} />
          <Box paddingTop={2} color="gray.dark">
            <FormattedMessage id="models.guest_profile.offers.empty_state" defaultMessage="No offers at this time but check back later" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Offers;
