import { type QueryHookOptions } from '~/lazy_apollo/client';
import { useLocationCurrencySettingsQuery } from '~/libs/gql/queries/locations/locationCurrencySettingsQuery.generated';
import type { LocationCurrencySettingsQuery, LocationCurrencySettingsQueryVariables } from '~/libs/gql/queries/locations/locationCurrencySettingsQuery.generated';
import { useSessionCart } from '../../useSessionCart';

export type UseLocationCurrencyHookSettingHookOptions = QueryHookOptions<LocationCurrencySettingsQuery, LocationCurrencySettingsQueryVariables>
export const useLocationCurrencySetting = (options: UseLocationCurrencyHookSettingHookOptions = {}) => {
  const { cartLocationId } = useSessionCart();

  const query = useLocationCurrencySettingsQuery({
    skip: !cartLocationId,
    variables: {
      locationId: cartLocationId,
    },
    ...options,
  });

  return { query };
};
