import React, { useState, useRef, useEffect, useContext } from 'react';
import { Box, Tabs, Tab } from '@popmenu/common-ui';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { useLazyQuery } from '~/lazy_apollo/client';
import { useDispatch } from 'react-redux';

import { createEvent } from '~/utils/eventable';
import { withIntl } from '../../../../utils/withIntl';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { openMenuItemModal } from '../../../../shared/DishActions';
import menuItemQuery from '../../../../libs/gql/queries/menus/menuItemQuery.gql';

import { ProfileContext } from '../../../../shared/ProfileContextProvider';

import PoppedDishes from './PoppedDishes';
import ReviewedDishes from './ReviewedDishes';
import RemindToTry from './RemindToTry';

import { useIsOrderingApp } from '../../hooks';

const ShowTabContent = (
  selectedTab,
  t,
  restaurant,
  tabHeight,
  menuItemDetails,
  setMenuItemDetails,
  windowHeight,
) => {
  const dispatch = useDispatch();
  const isOrderingApp = useIsOrderingApp();
  const [getMenuItem, { data, error, loading }] = useLazyQuery(
    menuItemQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => setMenuItemDetails(data),
    },
  );

  const dishClickHandler = (menuItemId, tab) => {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: `profile | favorites | ${tab} | dish-${tab === 'reviewed' ? 'photo-title' : 'card'}`,
      eventType: `profile_favorites_${tab}_dish_click`,
    });
    if (menuItemId) {
      getMenuItem(
        {
          variables: {
            id: menuItemId,
          },
        },
      );
    }
  };

  if (!error && !loading && menuItemDetails && !isOrderingApp) {
    dispatch(openMenuItemModal(menuItemDetails.menuItem.id));
  }
  if (!error && !loading && menuItemDetails && isOrderingApp) {
    dispatch({ context: { menuItemId: data.menuItem.id }, type: 'ordering.seeItem' });
  }

  switch (selectedTab) {
    case t('profile.favorites.popped_dishes_tab'):
      return (
        <PoppedDishes
          restaurant={restaurant}
          tabHeight={tabHeight}
          dishClickHandler={dishClickHandler}
          windowHeight={windowHeight}
        />
      );
    case t('profile.favorites.reviewed_dishes_tab'):
      return (
        <ReviewedDishes
          restaurant={restaurant}
          tabHeight={tabHeight}
          dishClickHandler={dishClickHandler}
          windowHeight={windowHeight}
        />
      );
    case t('profile.favorites.remind_try_tab'):
    case t('profile.favorites.bookmarks_tab'):
      return (
        <RemindToTry
          restaurant={restaurant}
          tabHeight={tabHeight}
          dishClickHandler={dishClickHandler}
          windowHeight={windowHeight}
        />
      );
  }
  return null;
};

const Favorites = ({ t, restaurant, windowHeight }) => {
  const {
    selectedFavoritesTab,
    setSelectedFavoritesTab,
  } = useContext(ProfileContext);
  const [menuItemDetails, setMenuItemDetails] = useState(null);
  const [tabHeight, setTabHeight] = useState(0);
  const tabRef = useRef(null);
  const modernLayout = restaurant.theme.dishLayout === 'modern_dish_layout';
  const remindTab = modernLayout ? t('profile.favorites.bookmarks_tab') : t('profile.favorites.remind_try_tab');
  const tabs = [
    {
      key: t('profile.favorites.popped_dishes_tab'),
      title: t('profile.favorites.popped_dishes_tab'),
    },
    {
      key: t('profile.favorites.reviewed_dishes_tab'),
      title: t('profile.favorites.reviewed_dishes_tab'),
    },
    {
      key: remindTab,
      title: remindTab,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const node = tabRef.current;
    setTabHeight(node.getBoundingClientRect().height);
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height={`calc(${windowHeight}px - 60px - 74px - 32px)`} // 60px Nav, 74px Header, 32px Margins
    >
      <Tabs
        variant="fullWidth"
        scrollButtons="auto"
        onChange={(e, tab) => {
          createEvent({
            eventableType: 'GuestProfile',
            eventLabel: `profile | favorites | ${tab.toLowerCase().replace(' ', '_')}_tab | ${tab.toLowerCase().replace(' ', '_')}`,
            eventType: `profile_favorites_${tab}_tab_click`,
          });
          setSelectedFavoritesTab(tab);
          setMenuItemDetails(null);
        }}
        value={selectedFavoritesTab}
        ref={tabRef}
      >
        {tabs.map(({ key, title }) => (
          <Tab key={key} label={title} value={key} />
        ))}
      </Tabs>
      <Box marginTop={3} />
      {ShowTabContent(selectedFavoritesTab, t, restaurant, tabHeight, menuItemDetails, setMenuItemDetails, windowHeight)}
    </Box>
  );
};

Favorites.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withRestaurant,
)(Favorites);
