import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Icon } from '@popmenu/common-ui';
import { ChevronLeft, X as XIcon } from '@popmenu/web-icons';
import IconButton from '@material-ui/core/IconButton';
import { propTypeDimension } from '~/utils/propTypes';
import { withRestaurant } from '../../../utils/withRestaurant';

import { ProfileContext } from '../../../shared/ProfileContextProvider';
import { profileHeaderStyles } from '../profile.styles';
import { AH } from '../../shared/AccessibleHeading';
import { useCloseProfile } from '../hooks';

const useStyles = makeStyles(profileHeaderStyles);

const ProfileHeader = ({ restaurantLogo, width }) => {
  const classes = useStyles();
  const { activeProfilePage, changeActivePage } = useContext(ProfileContext);
  const closeProfile = useCloseProfile();
  const { spacing } = useTheme();

  const isMobile = isWidthDown('md', width);

  const closeProfileModal = () => {
    closeProfile();
  };

  const setProfilePage = () => {
    changeActivePage('my_profile');
  };

  return (
    <Box
      p={`${spacing(0.75)}px ${spacing(1.25)}px`}
      boxShadow={1}
      className={classes.container}
    >
      {!isMobile && (
        <Avatar src={restaurantLogo} />
      )}
      {isMobile && activeProfilePage !== 'my_profile' && (
        <Box flexGrow={1}>
          <Button
            onClick={() => setProfilePage()}
            startIcon={<Icon icon={ChevronLeft} />}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="profile.return_text" defaultMessage="Back" />
          </Button>
        </Box>
      )}
      <Box paddingX={2} flexGrow={1}>
        <AH typography variant="h6" fontWeight="700">
          <FormattedMessage
            id="profile.nav_item_my_profile"
            defaultMessage="Profile"
          />
        </AH>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
      >
        <IconButton
          aria-label="close_profile"
          onClick={closeProfileModal}
          size="medium"
        >
          <Icon icon={XIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

ProfileHeader.defaultProps = {
  restaurantLogo: undefined,
};

ProfileHeader.propTypes = {
  restaurantLogo: PropTypes.string,
  width: propTypeDimension.isRequired,
};

export default compose(
  withRestaurant,
  mapProps(({ restaurant }) => ({
    restaurantLogo: restaurant.logoUrl,
  })),
)(withWidth()(ProfileHeader));
