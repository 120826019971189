import { getApolloClient } from '~/lazy_apollo';
import { usePopmenuConfig } from '~/utils/withPopmenuConfig';
import { useSnackbar } from '~/utils/withSnackbar';
import { useDestroySessionMutation } from '~/libs/gql/mutations/users/destroySessionMutation.generated';
import { CurrentSessionDocument } from '~/libs/gql/queries/users/currentSessionQuery.generated';
import { OrderingUserDocument } from '~/libs/gql/queries/ordering_app/orderingUser.generated';
import { PendingOrderingMenuItemCartDocument } from '~/libs/gql/queries/ordering_app/pendingOrderingMenuItemCart.generated';
import { useCloseProfile } from '../useCloseProfile';
import { useIsOrderingApp } from '../useIsOrderingApp';

export const useSignOutProfile = () => {
  const [destroySessionMutation, destroySessionMutationResult] = useDestroySessionMutation();
  const popmenuConfig = usePopmenuConfig();
  const { showSnackbarError } = useSnackbar();
  const isOrderingApp = useIsOrderingApp();
  const closeProfile = useCloseProfile();
  const refetchQueries = [
    PendingOrderingMenuItemCartDocument,
    ...(isOrderingApp ? [OrderingUserDocument] : []),
  ];

  const signOutProfile = () => {
    if (!destroySessionMutationResult.loading) {
      closeProfile();
      void destroySessionMutation({
        awaitRefetchQueries: true,
        onCompleted: (data) => {
          void getApolloClient().then(client => client.writeQuery({
            data: {
              // @ts-expect-error currentSession query not typed for destroy session data type
              currentSession: data.destroySession,
              popmenuConfig,
            },
            query: CurrentSessionDocument,
          }));
        },
        onError: (error) => {
          showSnackbarError(error);
        },
        refetchQueries,
      });
    }
  };

  return { signOutProfile };
};
