import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

const OrderHistoryDishExtras = ({ selectedGroup }) => (
  selectedGroup.selectedExtras.map(extra => (
    <Typography variant="subtitle2" color="textSecondary">
      <FormattedMessage
        id="profile.order_dish_extra_name"
        defaultMessage="{extraName}"
        values={{ extraName: extra.extraName }}
      />
    </Typography>
  ))
);

OrderHistoryDishExtras.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
};

export default OrderHistoryDishExtras;
