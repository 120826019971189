import React from 'react';
import { Box } from '@popmenu/common-ui';

import Loading from '../../../shared/Loading';
import ProfileNavigation from './ProfileNavigation';
import ProfilePageContentRegion from './ProfilePageContentRegion';
import { useCurrentUser } from '../../../shared/CurrentSessionProvider';
import { useProfileUser } from '../hooks';

const ProfileContent = () => {
  const currentUser = useCurrentUser();
  const profileUserQuery = useProfileUser();

  if (profileUserQuery.query.loading) {
    return <Loading />;
  }

  if (!currentUser?.id) {
    return null;
  }

  return (
    <Box display="flex" flexGrow={1}>
      <ProfileNavigation />
      <ProfilePageContentRegion />
    </Box>
  );
};

export default ProfileContent;
