import React from 'react';
import { Drawer } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';

// @ts-expect-error importing js
import { profileDrawerStyles } from './profile.styles';
// @ts-expect-error importing jsx
import ProfileHeader from './internal/ProfileHeader';
// @ts-expect-error importing jsx
import ProfileContent from './internal/ProfileContent';

import { AHLevelProvider } from '../shared/AccessibleHeading';

const useStyles = makeStyles(profileDrawerStyles);

type ProfileDrawerProps = {
  show: boolean
}
export const ProfileDrawer = ({ show }: ProfileDrawerProps) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={show}
      classes={classes}
    >
      <ProfileHeader />
      <AHLevelProvider>
        <ProfileContent />
      </AHLevelProvider>
    </Drawer>
  );
};
