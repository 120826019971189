import { type QueryHookOptions } from '~/lazy_apollo/client';
import { useSelector } from '~/utils/redux';
import { useGuestProfileUserQuery } from '~/libs/gql/queries/users/guestProfileUserQuery.generated';
import type { GuestProfileUserQuery, GuestProfileUserQueryVariables } from '~/libs/gql/queries/users/guestProfileUserQuery.generated';
import { useRestaurant } from '~/utils/withRestaurant';
import { useIsOrderingApp } from '../../useIsOrderingApp';
import { useCurrentUser } from '../../../../../shared/CurrentSessionProvider';

export type UseLocationCurrencyHookSettingHookOptions = QueryHookOptions<GuestProfileUserQuery, GuestProfileUserQueryVariables>
export const useProfileUser = (options: UseLocationCurrencyHookSettingHookOptions = {}) => {
  const currentUser = useCurrentUser();
  const restaurant = useRestaurant();
  const isOrderingApp = useIsOrderingApp();

  /**
   * When the profile is viewed from the orderingApp route
   * the selector must reference the orderingApp redux reducer states
  */
  const showProfileV2Modal = useSelector(state => state.modals.showProfileV2Modal);
  const orderingAppDialog = useSelector(state => state.onlineOrdering.dialog.value);
  const profileIsHidden = isOrderingApp ? orderingAppDialog !== 'profile' : !showProfileV2Modal;

  const query = useGuestProfileUserQuery({
    fetchPolicy: isOrderingApp ? 'cache-first' : 'cache-and-network',
    skip: profileIsHidden || !currentUser?.id || !restaurant?.id,
    variables: {
      restaurantId: restaurant?.id || -1,
      userId: currentUser?.id || -1,
    },
    ...options,
  });

  return { query };
};
