type GetOfferValidLocationUrlProps = {
  cartLocationId: number | undefined
  locationId: number | undefined
  locationSlug: string | undefined
  orderingOnlineUrl: string | undefined
}
export const getOfferValidLocationUrl = ({ cartLocationId, locationSlug, locationId, orderingOnlineUrl }: GetOfferValidLocationUrlProps) => {
  if (!cartLocationId) {
    let rootOrderingUrl = orderingOnlineUrl;
    if (rootOrderingUrl?.includes('#menu?location=')) {
      rootOrderingUrl = orderingOnlineUrl?.substring(0, orderingOnlineUrl.indexOf('#'));
    }
    return `${rootOrderingUrl}#menu?location=${locationSlug}`;
  } else if (cartLocationId === locationId) {
    return orderingOnlineUrl;
  }

  return undefined;
};
