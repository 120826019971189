import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Avatar } from '@popmenu/common-ui';
import { Clock, CreditCard, Gift, Profile, Star, Tag } from '@popmenu/web-icons';

import { useRestaurant } from '../utils/withRestaurant';
import { formatDate } from '../utils/time';
import { useProfileUser } from '../consumer/profile/hooks';

const INITIAL_PROFILE_STATE = {
  activeProfilePage: 'my_info',
  activeUser: {},
  loading: true,
  orderingOfferCodes: [],
  profileNavigationHeight: 70,
  profileNavigationWidth: 240,
  profilePages: {
    favorites: {
      display: true,
      hasCount: false,
      icon: Star,
      id: 'favorites',
      order: 3,
      title: 'Favorites',
    },
    gift_card: {
      display: true,
      hasCount: true,
      icon: Gift,
      id: 'gift_card',
      order: 2,
      profileCount: 'unusedGiftcards',
      title: 'Gift Cards',
    },
    my_info: {
      display: true,
      hasCount: false,
      icon: Avatar,
      id: 'my_info',
      order: 5,
      title: 'My Info',
    },
    my_profile: {
      display: false,
      id: 'my_profile',
      title: 'My Profile',
    },
    offers: {
      display: true,
      hasCount: true,
      icon: Tag,
      id: 'offers',
      order: 1,
      profileCount: 'unreadOffers',
      title: 'Offers',
    },
    order_history: {
      display: false,
      hasCount: false,
      icon: Clock,
      id: 'order_history',
      order: 4,
      title: 'Recent Orders',
    },
    payment: {
      display: false,
      hasCount: false,
      icon: CreditCard,
      id: 'payment',
      order: 6,
      title: 'Payment',
    },
  },
};

export const ProfileContext = React.createContext({
  ...INITIAL_PROFILE_STATE,
});

const ProfileContextProvider = ({ children, width }) => {
  // will be one of offers, gift_card, favorites, order_history, my_info *as default first time, will be offers other times, and payment
  const [activeProfilePage, setActiveProfilePage] = useState(isWidthDown('md', width) ? 'my_profile' : 'my_info');
  const [selectedFavoritesTab, setSelectedFavoritesTab] = useState('POPPED');
  const setProfilePage = useSelector(state => state.modals.setActiveProfilePage);
  const restaurant = useRestaurant();
  const profileUserQuery = useProfileUser();
  const guestProfileUser = profileUserQuery.query.data?.guestProfileUser;

  useEffect(() => {
    if (setProfilePage) {
      setActiveProfilePage(setProfilePage);
    }
  }, [setProfilePage]);

  const profilePages = useMemo(
    () => ({
      favorites: {
        display: true,
        hasCount: false,
        icon: Star,
        id: 'favorites',
        order: 3,
        title: 'Favorites',
      },
      gift_card: {
        display: true,
        hasCount: true,
        icon: Gift,
        id: 'gift_card',
        order: 2,
        profileCount: 'unusedGiftcards',
        title: 'Gift Cards',
      },
      my_info: {
        display: true,
        hasCount: false,
        icon: Profile,
        id: 'my_info',
        order: 5,
        title: 'My Info',
      },
      my_profile: {
        display: false,
        id: 'my_profile',
        title: 'My Profile',
      },
      offers: {
        display: true,
        hasCount: true,
        icon: Tag,
        id: 'offers',
        order: 1,
        profileCount: 'unreadOffers',
        title: 'Offers',
      },
      order_history: {
        display: restaurant.featureSetting.isOrderingEnabled,
        hasCount: false,
        icon: Clock,
        id: 'order_history',
        order: 4,
        title: 'Recent Orders',
      },
      payment: {
        display: false,
        hasCount: false,
        icon: CreditCard,
        id: 'payment',
        order: 6,
        title: 'Payment',
      },
    }), [
      restaurant.featureSetting.isOrderingEnabled,
    ],
  );

  const provided = useMemo(
    () => ({
      activeProfilePage,
      activeUser: {
        avatarUrl: guestProfileUser?.avatarUrl,
        name: guestProfileUser?.name,
        profileCreationDate: formatDate(guestProfileUser?.createdAt),
        unreadOffers: guestProfileUser?.orderingOfferCodes?.length,
        unusedGiftcards: guestProfileUser?.userGiftCards?.length,
      },
      changeActivePage: setActiveProfilePage,
      FETCH_LIMIT: 5,
      profileNavigationHeight: 70,
      profileNavigationWidth: 240,
      profilePages,
      restaurant,
      selectedFavoritesTab,
      setSelectedFavoritesTab,
    }),
    [
      activeProfilePage,
      profilePages,
      guestProfileUser,
      restaurant,
      selectedFavoritesTab,
    ],
  );

  let value;
  if (!profileUserQuery.query.loading && profileUserQuery.query.data && profileUserQuery.query.data.guestProfileUser) {
    value = provided;
  } else {
    value = INITIAL_PROFILE_STATE;
  }

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withWidth()(ProfileContextProvider);
