import React from 'react';
import { Box, MenuItem, TextField } from '@popmenu/common-ui';
import { useField } from 'react-form';
import { useIntl } from 'react-intl';
import { notBlankValidator } from '~/utils/forms';
import type { Maybe } from 'graphql/jsutils/Maybe';

export const BirthdayControls = () => {
  const intl = useIntl();
  const errorMessage = intl.formatMessage({ defaultMessage: 'required', id: 'my-info-tab.birthday-controls.birthday-required' });
  const birthMonthLabel = intl.formatMessage({ defaultMessage: 'Birth Month', id: 'my-info-tab.birthday-controls.birth-month' });
  const birthDayLabel = intl.formatMessage({ defaultMessage: 'Birth Day', id: 'my-info-tab.birthday-controls.birth-day' });
  const birthMonthField = useField<Maybe<string>>('birthMonth', {});
  // @ts-expect-error - cannot resolve
  const birthDayField = useField<Maybe<string>>('birthDay', { validate: birthMonthField.value ? notBlankValidator : undefined });
  const month = birthMonthField.value ? (parseInt(birthMonthField.value, 10) - 1) : 0;
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month] ?? 31;
  const onBirthMonthChange = () => {
    birthDayField.setValue(undefined);
  };

  return (
    <Box mb={3} display="flex" gridGap={16}>
      <TextField select label={birthMonthLabel} id="birthmonth" style={{ flexGrow: 1 }} {...birthMonthField.getInputProps({ onChange: onBirthMonthChange })}>
        {Array.from({ length: 12 }, (_, i) => ({ label: i + 1, value: String(i + 1) })).map(option => (
          <MenuItem value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField select label={birthDayLabel} id="birthday" style={{ flexGrow: 1 }} {...birthDayField.getInputProps({ error: Boolean(birthDayField.meta.error), helperText: birthDayField.meta.error ? errorMessage : undefined })}>
        {Array.from({ length: daysInMonth }, (_, i) => ({ label: i + 1, value: String(i + 1) })).map(option => (
          <MenuItem value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
