import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Box, Typography } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createEvent } from '~/utils/eventable';
import { setOrderingOfferCode, setProfileEventTracking } from '../../../shared/ConsumerActions';
import { formatDate } from '../../../utils/time';
import { useOrderingApplyOfferOrGiftCardMutation } from '../../../libs/gql/mutations/menu_item_carts/orderingApplyOfferOrGiftCardMutation.generated';
import { useSnackbar } from '../../../utils/withSnackbar';

import OfferDetailsModal from '../../online_ordering/OfferDetailsModal';
import { orderingOfferType } from '../../online_ordering/utils';
import BasicModal from '../../../admin/shared/BasicModal';

import { profileCardsStyles } from '../profile.styles';
import { AH } from '../../shared/AccessibleHeading';
import { useCloseProfile, useOrderCartUrl, useIsOrderingApp, useLocationCurrencySetting, useSessionCart } from '../hooks';
import { getOfferValidLocationUrl } from '../utils';
import WrongLocationAlert from './WrongLocationAlert';

const useStyles = makeStyles(profileCardsStyles);

const useMessages = () => {
  const intl = useIntl();
  return {
    code: code => intl.formatMessage({ defaultMessage: 'Offer Code: {code}', id: 'models.guest_profile.offer.offer_code' }, { code }),
    date: date => intl.formatMessage({ defaultMessage: 'Expire Date: {date}', id: 'models.guest_profile.offer.offer_expiration' }, { date }),
    location: offerType => intl.formatMessage({ defaultMessage: 'This offer applies to {offerType}', id: 'models.guest_profile.offer.offer_location' }, { offerType }),
    offerApplied: intl.formatMessage({ defaultMessage: 'Offer Applied to Cart', id: 'models.guest_profile.offer.offer_applied' }),
    offerName: offerName => intl.formatMessage({ defaultMessage: '{offerName}', id: 'models.guest_profile.offer.offer_name' }, { offerName }),
    redeemNow: intl.formatMessage({ defaultMessage: 'Redeem Now', id: 'models.guest_profile.offer.redeem_now' }),
    seeDetails: intl.formatMessage({ defaultMessage: 'See Details', id: 'models.guest_profile.offer.see_details' }),
    specificLocation: location => intl.formatMessage({ defaultMessage: 'Offer valid at {location} location', id: 'models.guest_profile.offer.specific_location' }, { location }),
    valid: intl.formatMessage({ defaultMessage: 'Offer valid at all locations', id: 'models.guest_profile.offer.all_locations' }),
  };
};

const OfferCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const orderCartUrl = useOrderCartUrl();
  const closeProfile = useCloseProfile();
  const isOrderingApp = useIsOrderingApp();
  const orderingOnlineUrl = orderCartUrl();
  const messages = useMessages();
  const { showSnackbar, showSnackbarError } = useSnackbar();
  const { cart, cartLocationId } = useSessionCart();
  const locationCurrencySetting = useLocationCurrencySetting();
  const [applyOffer] = useOrderingApplyOfferOrGiftCardMutation();
  const profileEventTracking = useSelector(state => state.consumer.profileEventTracking); // null in orderingApp route
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showWrongLocationAlert, setShowWrongLocationAlert] = useState(false);
  const isLocationOffer = props.locationSlug && props.locationName && props.locationId;
  const expirationDisplay = props.expirationDate ? formatDate(props.expirationDate, 'MM/dd/yyyy') : 'N/A';
  const validOfferUrl = getOfferValidLocationUrl({ cartLocationId, locationId: props.locationId, locationSlug: props.locationSlug, orderingOnlineUrl });
  const invalidLocation = isLocationOffer && cartLocationId && props.locationId !== cartLocationId;

  const getQualifyingDishes = (type) => {
    let dishes = [];
    const dishType = props.orderingOfferCode?.orderingOffer?.orderingOfferBxgyDetail[type];
    dishType.forEach((dish) => {
      dishes.push(dish.dish.name);
    });
    dishes = dishes.join(', ');
    return dishes;
  };

  const dispatchOffer = () => {
    if (invalidLocation) {
      setShowWrongLocationAlert(true);
    } else {
      dispatch(setOrderingOfferCode(props.offerCode));
      if (!profileEventTracking.some(event => event === 'offer')) {
        dispatch(setProfileEventTracking([...profileEventTracking, 'offer']));
      }

      closeProfile();
      history.push(isLocationOffer ? validOfferUrl : orderingOnlineUrl);
    }
  };

  const handleRedeemNow = () => {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: 'profile | offers | redeem_now_button | redeem_now',
      eventType: 'profile_offers_redeem_now_click',
    });
    if (isOrderingApp && !invalidLocation) {
      applyOffer({
        onCompleted: () => {
          closeProfile();
          showSnackbar(messages.offerApplied);
        },
        onError: error => showSnackbarError(error),
        variables: {
          cartType: cart.cartType,
          input: {
            checkActiveOffersOnly: true,
            code: props.offerCode || '',
            menuItemCartId: cart?.id,
          },
        },
      });
    } else {
      dispatchOffer();
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        border={1}
        borderRadius="borderRadius"
        borderColor="gray.dark"
        justifyContent="center"
        alignItems="center"
        padding={1.5}
        data-cy={'offer_card'}
      >
        {props.photo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom={1.5}
            overflow="hidden"
          >
            <img
              src={props.photo.thumbnailUrl}
              alt={props.photo.originalFilename}
              className={classes.cardImg}
            />
          </Box>
        )}
        <Box marginBottom={0.5}>
          <AH typography variant="h5" align="center">
            {messages.offerName(props.offerName)}
          </AH>
        </Box>
        { isLocationOffer && (
          <Box>
            <Typography>
              {messages.specificLocation(props.locationName)}
            </Typography>
          </Box>
        )}
        { !isLocationOffer && (
          <Box>
            <Typography>
              {messages.valid}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography>
            {messages.code(props.offerCode)}
          </Typography>
        </Box>
        <Box>
          {messages.date(expirationDisplay)}
        </Box>
        <Box textAlign="center">
          {messages.location(props.orderingOfferCode.orderingOffer.orderingOfferBxgyDetail ? 'select items' : orderingOfferType(props.orderingOfferCode))}
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box width="50%" paddingRight={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setShowOfferModal(true)}
              data-cy={'see_details'}
            >
              {messages.seeDetails}
            </Button>
          </Box>
          <Box width="50%" paddingLeft={1}>
            <Button
              data-cy={'redeem_now'}
              fullWidth
              variant="contained"
              disabled={!props.orderingOfferCode.orderingOffer.canBeUsedToday}
              color="primary"
              style={{ padding: '6px 16px' }}
              onClick={handleRedeemNow}
            >
              {messages.redeemNow}
            </Button>
          </Box>
        </Box>
      </Box>
      <BasicModal
        closeModal={() => setShowOfferModal(false)}
        show={showOfferModal}
        size="sm"
        title="Offer Details"
      >
        <OfferDetailsModal
          locationName={props.locationName}
          orderingOfferCode={props.orderingOfferCode}
          getQualifyingDishes={getQualifyingDishes}
          amount={props.orderingOfferCode.orderingOffer.amount}
          currency={locationCurrencySetting.query.data?.location?.currency}
        />
      </BasicModal>
      <WrongLocationAlert open={showWrongLocationAlert} closeFunction={() => setShowWrongLocationAlert(false)} />
    </React.Fragment>
  );
};

export default OfferCard;
