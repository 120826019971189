import { useIsOrderingApp } from '../useIsOrderingApp';
import { useCurrentSession, type CurrentSession } from '../../../../shared/CurrentSessionProvider';

export const useSessionCart = () => {
  const currentSession: CurrentSession = useCurrentSession();
  const isOrderingApp = useIsOrderingApp();
  // @ts-expect-error orderingApp usage applies locationId to session pendingMenuItemCart that's not typed for legacy usage
  const cartLocationId = isOrderingApp ? currentSession.pendingMenuItemCart?.locationId as number | undefined : currentSession.pendingMenuItemCart?.location?.id;

  return { cart: currentSession.pendingMenuItemCart, cartLocationId };
};
