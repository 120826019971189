import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

const OrderHistoryDishModifiers = ({ selectedGroup }) => (
  selectedGroup.selectedModifiers.map(modifier => (
    <Typography variant="subtitle2" color="textSecondary">
      <FormattedMessage
        id="profile.order_dish_modifier_name"
        defaultMessage="{modifierName}"
        values={{ modifierName: modifier.modifierName }}
      />
    </Typography>
  ))
);

OrderHistoryDishModifiers.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
};

export default OrderHistoryDishModifiers;
