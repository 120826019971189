import { useSelector } from '~/utils/redux';
import { useRestaurant } from '~/utils/withRestaurant';
import { useCurrentUser } from '../../../../shared/CurrentSessionProvider';
import { useProfileUser } from '../data';

/**
 * This hook works exclusively for the legacy online ordering flow
 * @returns restaurant default order url, or otherwise provide catering url if no default exists
 */
export const useOrderCartUrl = () => {
  // @ts-expect-error legacy redux state not typed
  const activeCartLocationSlug = useSelector(state => state.consumer.activeCartLocationSlug as string | undefined);
  const profileUserQuery = useProfileUser();
  const restaurant = useRestaurant();
  const currentUser = useCurrentUser();
  const orderPageUrl = profileUserQuery.query.data?.restaurant?.onlineOrderDefaultUrl || profileUserQuery.query.data?.restaurant?.onlineOrderCateringUrl;
  const restaurantLocations = profileUserQuery.query.data?.restaurant?.locations || [];

  const orderCartUrl = (specifiedLocationSlug: string, useCatering: boolean) => {
    // if catering is specified, provide catering url, otherwise provide restaurant default (which may also be catering)
    const baseOrderUrl = useCatering ? profileUserQuery.query.data?.restaurant?.onlineOrderCateringUrl : orderPageUrl;

    // use specified location if provided
    if (specifiedLocationSlug) {
      return `${baseOrderUrl}#menu?location=${specifiedLocationSlug}`;
    }

    // default to active location if present and no location is specified
    if (activeCartLocationSlug) {
      return `${baseOrderUrl}#menu?location=${activeCartLocationSlug}`;
    }

    // if no specified or active location, default to favorite or first location
    if (!activeCartLocationSlug) {
      const favoriteLocationSlug = currentUser?.followers?.find(follower => follower.restaurantId === restaurant?.id)?.favoriteLocation?.slug || null;

      return `${baseOrderUrl}#menu?location=${favoriteLocationSlug || restaurantLocations[0]?.slug}`;
    }

    // if getting started enabled, and no specified or active location, visit getting started
    return baseOrderUrl;
  };

  return orderCartUrl;
};
