import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@popmenu/common-ui';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import { propTypeDimension } from '~/utils/propTypes';
import Favorites from '../navigation_tabs/favorites/Favorites';

import { ProfileContext } from '../../../shared/ProfileContextProvider';
import MyInfo from '../navigation_tabs/MyInfo';
import Offers from '../navigation_tabs/Offers';
import OrderHistoryPage from '../navigation_tabs/order_history/OrderHistoryPage';
import GiftCardsPage from '../navigation_tabs/gift_cards/GiftCardsPage';

import { profilePageContentRegionStyles } from '../profile.styles';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

const useStyles = makeStyles(profilePageContentRegionStyles);

const ProfilePageContentRegion = ({ width }) => {
  const {
    activeProfilePage,
    profilePages,
    profileNavigationHeight,
    profileNavigationWidth,
  } = useContext(ProfileContext);
  const classes = useStyles();

  const isMobile = isWidthDown('sm', width);
  const isWindowClient = typeof window === 'object';
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const renderActivePage = () => {
    switch (activeProfilePage) {
      case 'offers':
        return <Offers />;
      case 'gift_card':
        return <GiftCardsPage />;
      case 'favorites':
        return <Favorites windowHeight={windowHeight} />;
      case 'order_history':
        return <OrderHistoryPage />;
      case 'payment':
        return <React.Fragment />;
      default:
        return <MyInfo windowHeight={windowHeight} />;
    }
  };

  useEffect(() => {
    function setSize() {
      setWindowHeight(window.innerHeight);
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize);

      return () => window.removeEventListener('resize', setSize);
    }

    return windowHeight;
  }, [isWindowClient, windowHeight]);

  if (!isMobile || (isMobile && activeProfilePage !== 'my_profile')) {
    return (
      <Box width={
          /* eslint-disable sort-keys */
          { xs: '100%', sm: `calc(100% - ${profileNavigationWidth}px)` }
          /* eslint-enable sort-keys */
        }
      >
        <Box
          padding={isMobile ? 2 : 4}
          display="flex"
          flexDirection="column"
          height={`calc(${windowHeight}px - ${profileNavigationHeight}px)`}
          overflow="hidden"
        >
          <Box marginBottom={4}>
            <AH
              typography
              variant="h4"
              color="primary"
              className={classes.title}
            >
              <FormattedMessage
                id={`guest_profile.tab_title.${activeProfilePage}`}
                defaultMessage="{title}"
                values={{ title: profilePages[activeProfilePage].title }}
              />
            </AH>
          </Box>
          <AHLevelProvider>
            {renderActivePage()}
          </AHLevelProvider>
        </Box>
      </Box>
    );
  }

  return null;
};

ProfilePageContentRegion.propTypes = {
  width: propTypeDimension.isRequired,
};

export default withWidth()(ProfilePageContentRegion);
