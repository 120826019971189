import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button } from '@popmenu/common-ui';
import { useSignOutProfile } from '../hooks';

const ProfileSignOutButton = () => {
  const { signOutProfile } = useSignOutProfile();

  return (
    <Box my={1} mx={2}>
      <Button
        fullWidth
        variant="outlined"
        onClick={signOutProfile}
      >
        <FormattedMessage id="sessions.sign_out" defaultMessage="Sign Out" />
      </Button>
    </Box>
  );
};

export default ProfileSignOutButton;
